@import url('https://fonts.googleapis.com/css?family=Bebas+Neue|Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Belgrano|Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
// font-family: 'Belgrano', serif;
// font-family: 'Montserrat', sans-serif;
html{
  // font-size: 62.5%;
  font-family: 'Montserrat', sans-serif;
}

$mobile: "only screen and (max-width: 500px)";
$tablet: "only screen and (max-width: 700px)";
$desktop: "only screen and (min-width: 701px)";
$tablet: 650px;

:root{
  --splash-header-1:'Oswald', sans-serif;
  --splash-header-2:'Belgrano', serif;
  --splash-body-1: 'Montserrat', sans-serif;
  --splash-links:'Bebas Neue', cursive;

  --header-bebas:'Bebas Neue', cursive;
  --white: #fff;
  --green:#B9D8C2;
  --light-blue:#9AC2C9;
  --blue-grey: #8AA1B1;
  --black: #000;
  --yellow: #FFCB47;
}

 ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background: transparent;
  } 
  
  /* Track */
   ::-webkit-scrollbar-track {
    background: transparent;
  } 
  
  /* Handle */
   ::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 10px;
  } 
  
  /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } 


/*-------------App------------  */

body{
  // font: var(--splash-body-1);
  color: var(--white);
  background-color: var(--black);
}

h1,h2,h3{
  font: var(--splash-header-1);
  font-weight: 900;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/*--------------Splash--------------- */
.MainSplashContainer{
  // border: 1px solid green;
  height:70vh;
  width: 100%;
  // margin:9% 0;
  padding: 2rem 0 3rem;
  z-index: 1;
  background-color: var(--white);
  overflow: hidden;
  
  .Splash_Container{
    text-align: right;
    padding: 4% 0;    
  }

  #Splash_Hello{
    font-size:  6rem;
    font-family: var(--splash-header-1);
    font-weight: normal;
    color: var(--black);
    // text-shadow: 3px 2px #646464b4;
    margin:0;
    -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.3s both;
    animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.3s both;
  }

  #Splash_Message{
    font-size: 8.5rem;
    font-family: var(--splash-header-1);
    font-weight: normal;
    color: var(--black);
    // text-shadow: 3px 2px #646464b4;
    -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000)0.4s both;
    animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000)0.4s both;
    margin:0;
  }

  #Splash_Message2{
    font-size: 4rem;
    font-family: var(--splash-header-1);
    font-weight: normal;
    color: var(--black);
    // text-shadow: 3px 2px #646464b4;
    margin:0;
    border-bottom: 8px double var(--black);
    border-bottom-right-radius: 10px;
    -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000)0.5s both;
    animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000)0.5s both;
  }

  #splash_nav{
    font-size: 3.2rem;
    color: var(--black);
    font-family: var(--splash-body-1);
    font-weight: normal;
    padding:2rem 3rem;
    text-align: center;

  }

  .splash_nav_container{
    width: 100%;
    
    .splash_link{
      -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530).3s both;
      animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530).3s both;
      
    }

  }

  @media only screen and (max-width:1290px) {
    #Splash_Hello{
      font-size: 6.4rem;
    }
    #Splash_Message{
      font-size: 7.2rem;
    }
    #Splash_Message2{
      font-size: 4rem;
    }
  }

  @media only screen and (max-width:1092px) {
    .splash_nav_container{
      text-align: center;
    }
    #Splash_Hello{
      font-size: 5.3rem;
      text-align: center;

    }
    #Splash_Message{
      font-size: 6rem;
      text-align: center;

    }
    #Splash_Message2{
      font-size: 3.5rem;
      text-align: center;
    }
    #splash_image_container{
      display: none;
    }
  }

  @media only screen and (max-width:$tablet) {
    .MainSplashContainer{
      height:"auto";
      padding-bottom:0 ;
    }
    .splash_nav_container{
      display: flex;
      flex-direction: column;
    }
    #Splash_Hello{
      font-size: 5.3rem;
      text-align: center;
    }
    #Splash_Message{
      font-size: 4rem;
      text-align: center;

    }
    #Splash_Message2{
      font-size: 3rem;
      text-align: center;
    }
    #splash_image_container{
      display: none;
    }
    #splash_nav{
      font-size: 2.5rem;
      padding: 1rem 3rem;

    }
  }
}

.splash_text_container{
  background-color: white;
  z-index: 2;

  .splash_text{
  padding:1rem 3rem;
    margin: 2rem 0;
    font-family: var(--splash-body-1);
    font-size: 1rem;
    position: relative;
    top: -3rem;
    z-index: 3;
    
  }
}


.nav_menu{
  background-color: green($color: #000000);
}

.code_home_header {
  background-color:yellow,

}


// Animations
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


@-webkit-keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}
@keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}
